<template>
  <div>
    <v-container>
      <v-row justify="center" align-sm="center">
        <v-col cols="12" sm="12">
          <div class="rounded-lg title-bg py-2 px-4 mb-4">
            <h2 class="white--text">Services</h2>
          </div>
        </v-col>
      </v-row>

      <!-- <v-row justify="center" align-sm="center">
        <v-col
          
          cols="12"
          class=""
        > -->
      <v-card
        v-for="(service, index) in services"
        :key="index"
        @click="(sheet = !sheet), showService(service.link)"
        class="rounded-md card-border my-6 py-2 px-4"
        light
        elevation="2"
      >
        <v-row class="">
          <v-col cols="3" class="d-flex justify-center align-center">
            <v-avatar size="72" :class="service.icon_bg"
              ><v-icon large color="white">{{ service.icon }}</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="8">
            <span class="subtitle-1">
              <b>{{ service.title }}</b>
            </span>
            <p class="caption text-justify">
              {{ service.text }}
            </p>
          </v-col>
          <v-col cols="1" class="d-flex justify-center align-center">
            <v-btn
              icon
              color="blue-grey lighten-4"
              @click="(sheet = !sheet), showService(service.link)"
            >
              <v-icon>fas fa-angle-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <!-- </v-col>
      </v-row> -->
    </v-container>
    <v-bottom-sheet v-model="sheet" inset>
      <v-sheet class="pa-2 rounded-t-lg" height="auto">
        <div class="rounded-t-lg py-2 px-1 grey lighten-4 text-right">
          <v-btn icon color="grey lighten-1" @click="sheet = !sheet">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </div>
        <v-row
          class="d-flex justify-center align-center"
          v-if="this.service == 'loan'"
        >
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-img
              alt="100 miles realtors services"
              class="shrink mt-2"
              src="../assets/home-loan.jpg"
              transition="scale-transition"
              max-height="250"
              max-width="350"
            />
          </v-col>
          <v-col cols="12" class="">
            <span class="subtitle-1 px-3">
              <b>Home Loan</b>
            </span>
            <p class="caption px-3">
              Whether you are salaried or self-employed, you can get a home
              loan.
            </p>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center align-center"
          v-if="this.service == 'decor'"
        >
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-img
              alt="100 miles realtors services"
              class="shrink mt-2"
              src="../assets/home-decor.jpg"
              transition="scale-transition"
              max-height="250"
              max-width="350"
            />
          </v-col>
          <v-col cols="12" class="">
            <span class="subtitle-1 px-3">
              <b>Home Decore</b>
            </span>
            <p class="caption px-3">
              We are offering budget friendly packages for home decor and
              interior design.
            </p>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center align-center"
          v-if="this.service == 'valuation'"
        >
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-img
              alt="100 miles realtors services"
              class="shrink mt-2"
              src="../assets/valuation.jpg"
              transition="scale-transition"
              max-height="250"
              max-width="350"
            />
          </v-col>
          <v-col cols="12" class="">
            <span class="subtitle-1 px-3">
              <b>Property Valuation</b>
            </span>
            <p class="caption px-3">
              Our aim is to owner get right value of their property as per
              market valuation.
            </p>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center align-center"
          v-if="this.service == 'approval'"
        >
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-img
              alt="100 miles realtors services"
              class="shrink mt-2"
              src="../assets/approval.jpg"
              transition="scale-transition"
              max-height="250"
              max-width="350"
            />
          </v-col>
          <v-col cols="12" class="">
            <span class="subtitle-1 px-3">
              <b>Plan Approval</b>
            </span>
            <p class="caption px-3">
              Our experts will help you for plan approval.
            </p>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center align-center"
          v-if="this.service == 'insurance'"
        >
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-img
              alt="100 miles realtors services"
              class="shrink mt-2"
              src="../assets/insurance.jpg"
              transition="scale-transition"
              max-height="250"
              max-width="350"
            />
          </v-col>
          <v-col cols="12" class="">
            <span class="subtitle-1 px-3">
              <b>Property Insurance</b>
            </span>
            <p class="caption px-3">
              Our experts will guide you choose right insurance for your
              property.
            </p>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center align-center"
          v-if="this.service == 'pest_control'"
        >
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-img
              alt="100 miles realtors services"
              class="shrink mt-2"
              src="../assets/pest-control.jpg"
              transition="scale-transition"
              max-height="250"
              max-width="350"
            />
          </v-col>
          <v-col cols="12" class="">
            <span class="subtitle-1 px-3">
              <b>Pest Control</b>
            </span>
            <p class="caption px-3">
              Our aim is to ensure a healthy and pest-free environment for you
              and your family
            </p>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center align-center"
          v-if="this.service == 'cleaning'"
        >
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-img
              alt="100 miles realtors services"
              class="shrink mt-2"
              src="../assets/house-keeping.jpg"
              transition="scale-transition"
              max-height="250"
              max-width="350"
            />
          </v-col>
          <v-col cols="12" class="">
            <span class="subtitle-1 px-3">
              <b>House Cleaning</b>
            </span>
            <p class="caption px-3">
              clean house is a key of healthy life. we are providing budget
              friendly cleaning service for your home..
            </p>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
export default {
  name: "Services",
  data() {
    return {
      sheet: false,
      imgBaseUrl: process.env.BASE_URL,
      sTitle: "",
      sImg: "",
      sDesc: "",
      service: "",
      services: [
        {
          icon: "fas fa-hand-holding-usd",
          icon_bg: "home-loan-gradient",
          title: "Home Loan",
          text: "Whether you are salaried or self-employed, you can get a home loan.",
          link: "loan",
        },
        {
          icon: "fas fa-paint-roller",
          icon_bg: "home-decor-gradient",
          title: "Home Decor",
          text: "We are offering budget friendly packages for home decor and interior design.",
          link: "decor",
        },
        {
          icon: "fas fa-rupee-sign",
          icon_bg: "property-valuation-gradient",
          title: "Property Valuation",
          text: "Our aim is to owner get right value of their property as per market valuation.",
          link: "valuation",
        },
        {
          icon: "fas fa-map",
          icon_bg: "plan-approval-gradient",
          title: "Plan Approval",
          text: "Our experts will help you for plan approval.",
          link: "approval",
        },
        {
          icon: "fas fa-house-damage",
          icon_bg: "property-insurance-gradient",
          title: "Property Insurance",
          text: "Our experts will guide you choose right insurance for your property.",
          link: "insurance",
        },
        {
          icon: "fas fa-bug",
          icon_bg: "pest-control-gradient",
          title: "Pest Control",
          text: "Our aim is to ensure a healthy and pest-free environment for you and your family.",
          link: "pest_control",
        },
        {
          icon: "fas fa-broom",
          icon_bg: "house-cleaning-gradient",
          title: "House Cleaning",
          text: "we are providing budget friendly cleaning service for your home and office.",
          link: "cleaning",
        },
      ],
    };
  },
  methods: {
    showService(service) {
      //console.log("services :", service);
      this.service = service;
      // switch (service) {
      //   case "loan":
      //     this.sTitle = "Home Loan";
      //     this.sImg = "home-loan.jpg";
      //     this.sDesc =
      //       "Whether you are salaried or self-employed, you can get a home loan. Terms for both kinds of applicants are the same. Only difference usually is in the set of documents to be submitted for home loan. Earnings and creditworthiness are the key factors to determine loan eligibility.";
      //     break;
      //   case "decor":
      //     this.sTitle = "Home decor";
      //     this.sImg = "home-decor.jpg";
      //     this.sDesc =
      //       "We are offering budget friendly packages for home decor and interior design.";
      //     break;
      //   case "valuation":
      //     this.sTitle = "Property Valuation";
      //     this.sImg = "valuation.jpg";
      //     this.sDesc = "Our aim is to owner get right value of their property.";
      //     break;
      //   case "approval":
      //     this.sTitle = "Plan Approval";
      //     this.sImg = "approval.jpg";
      //     this.sDesc = "Our experts will help you for plan approval.";
      //     break;
      //   case "insurance":
      //     this.sTitle = "Property Insurance";
      //     this.sImg = "insurance.jpg";
      //     this.sDesc =
      //       "Our experts will guide you choose right insurance for your property.";
      //     break;
      //   case "pest_control":
      //     this.sTitle = "Pest Control";
      //     this.sImg = "pest-control.jpg";
      //     this.sDesc =
      //       "Our aim is to ensure a healthy and pest-free environment for you and your family";
      //     break;
      //   case "cleaning":
      //     this.sTitle = "House Cleaning";
      //     this.sImg = "house-keeping.jpg";
      //     this.sDesc =
      //       "clean house is a key of healthy life. we are providing budget friendly cleaning service for your home.";
      // }
    },
  },
};
</script>

<style>
.card-border {
  border-color: #fff;
  border-width: 4px;
}

.home-loan-gradient {
  background-image: linear-gradient(135deg, #f02fc2 0%, #6094ea 100%);
}
.home-decor-gradient {
  background-image: linear-gradient(135deg, #fad961 0%, #f76b1c 100%);
}
.property-valuation-gradient {
  background-image: linear-gradient(135deg, #13f1fc 0%, #0470dc 100%);
}
.plan-approval-gradient {
  background-image: linear-gradient(135deg, #f36265 0%, #961276 100%);
}
.property-insurance-gradient {
  background-image: linear-gradient(135deg, #42e695 0%, #3bb2b8 100%);
}
.pest-control-gradient {
  background-image: linear-gradient(135deg, #f65599 0%, #4d0316 100%);
}
.house-cleaning-gradient {
  background-image: linear-gradient(135deg, #c3ec52 0%, #0ba29d 100%);
}
.title-bg {
  background-image: linear-gradient(235deg, #c2e4fe 0%, #0190ff 100%);
}
</style>
